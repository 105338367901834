import Amplify from '@aws-amplify/api';

// *************************** Import CSS *************************** //

import "./wc2023-feature.css";

// *************************** Import Images *************************** //

// ** WINTER CAMP 2023 - Themed Flyer ** //
import "/src/assets/flyers/girls-code-sav-winter-camp-2023-1.png";

// ** WINTER CAMP 2023 - Headshots ** //
import "/src/assets/headshots/melanie-headshot.png";
import "/src/assets/headshots/daniette-headshot.jpg";
import "/src/assets/headshots/jessie-may-headshot.jpg";

// *************************************************** Insert Winter Camp 2023 Feature **************************************************** //

class WC2023Feature extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

<!-- **************************************** BEGIN: WINTER CAMP 2023 ********************************************* -->

  <div class="container">
  
  <!-- ************** BEGIN: Title of Section ************** -->

      <div class="row col-lg-12 justify-content-center">

        <span class="col-3 col-md-2 col-lg-1 badge badge-dark-purple text-white mt-4">
          <img src="white-brackets.png" height="30px" alt="html brackets">
        </span>

        <p class="section-title-size serif-heading bold mx-auto text-center mt-2 pb-0 mb-0 text-hot-pink">
          Winter Camp 2023
        </p>

      </div>

  <!-- ************** END: Title of Section ************** -->


  <!-- ********** BEGIN: Flyer Image ********** -->

      <div class="row winter-camp-graphic mt-3">

        <img src="girls-code-sav-winter-camp-2023-1.png" alt="Girls Code Savannah winter camp flyer">

      </div>

  <!-- ********** END: Flyer Image ********** -->

  
  <!-- ************* BEGIN: Camp Description *********** -->

    <div class="col-12 col-lg-10 mt-2 mt-xl-0 text-center mx-auto">

      <p class="text-size-body mt-4 pb-2 ps-2 ps-md-0 text-dark-blue text-start">
        Our <a class="text-hot-pink serif-heading text-size-h3 bold" href="winter-camp-2023.html">FREE 2023 Winter Camp</a>
        was held December 18th - 22nd at the Georgia Southern University-Armstrong Campus. It was our largest Girls Code Savannah camp yet with
        <span class="text-hot-pink serif-heading text-size-h3 bold">a record 55 students in 5th - 8th grade attending from across all 8 Savannah Chatham County Public School Districts!</span>
      </p>

      <p class="text-size-body mt-3 pb-2 ps-2 ps-md-0 text-dark-blue text-start">
        In two Georgia Southern University computer labs, parallel coding classes took place &#8212; one for beginner website coders and another for 
        <a class="text-hot-pink serif-heading text-size-h3 bold" href="summer-camp-2023.html">Summer Camp 2023</a> & 
        <a class="text-hot-pink serif-heading text-size-h3 bold" href="#fall-2023">Fall 2023 Saturday Sesssions</a> returning coders.
      </p>

      <p class="text-size-body pb-0 ps-2 ps-md-0 text-dark-blue text-start">
        The beginner‐level coding lab taught introduction to HTML and CSS for those new to website coding while the second coding lab allowed continuing website coders to build upon their already-created websites and to learn new JavaScript programming skills, making their current websites more dynamic.
      </p>

      <p class="text-size-body pb-0 mb-0 ps-2 ps-md-0 text-dark-blue text-start">
        In addition to building their real-world coding skills throughout the week, campers also engaged with several female guest instructors and speakers who inspired them with interactive sessions and stories of how they are using tech daily to develop their own careers, businesses, and dreams of the future.
      </p>

    </div>

  <!-- ************* END: Camp Description *********** -->


  <!-- *************** BEGIN: Invited Guest Instructors & Speakers ************ -->

      <!-- Row of Guests -->

      <div class="row col-12 d-flex justify-content-center mx-auto mt-0">

        <!-- BEGIN: Guest 1  -->

        <div class="col-12 col-md-4 col-lg-3 mt-0">

          <!-- Headshot -->

          <div class="text-center mt-4 mb-1">
            <img class="img-fluid headshot-img" src="jessie-may-headshot.jpg" alt="Jessie May Wolf, Founder of the HeartRise Method">
          </div>

          <!-- Title -->

          <div class="text-center">
            <a class="text-hot-pink serif-heading text-size-h3 bold" href="https://www.linkedin.com/in/jessiemaywolfe/" target="_blank">Jessie May Wolfe</a>
            <p class="text-dark-blue mb-0">Creative + Entrepreneur</p>
            <p class="text-dark-blue mb-0">Founder, 
              <a class="text-hot-pink serif-heading text-size-h4 bold" href="https://www.heartrisemovement.com/" target="_blank">
              HeartRise Movement</a>
            </p>
          </div>

        </div>

        <!-- END: Guest 1 -->

        <!-- BEGIN: Guest 2  -->

        <div class="col-12 col-md-4 col-lg-3">

          <!-- Headshot -->

          <div class="text-center mt-4 mb-1">
            <img class="img-fluid headshot-img" src="daniette-headshot.jpg" alt="Danietté Thomas, CEO of DANIETTÉ Custom Couture">
          </div>

          <!-- Title -->

          <div class="text-center">
            <a class="text-hot-pink serif-heading text-size-h3 bold" href="https://www.linkedin.com/in/daniette/" target="_blank">
            Danietté Thomas</a>
            <p class="text-dark-blue mb-0">Fashion Designer</p>
            <p class="text-dark-blue mb-0">CEO, 
              <a class="text-hot-pink serif-heading text-size-h4 bold" href="https://www.arionnacollection.com/" target="_blank">
              ARIONNA Collection</a>
            </p>
          </div>

        </div>

        <!-- END: Guest 2 -->


        <!-- BEGIN: Guest 3  -->

        <div class="col-12 col-md-4 col-lg-3">

          <!-- Headshot -->

          <div class="text-center mt-4 mb-1">
            <img class="img-fluid headshot-img" src="melanie-headshot.png" alt="Melanie DeFilippis, CEO of Leto Maternal Health">
          </div>

          <!-- Title -->

          <div class="text-center">
            <p class="melanie text-hot-pink serif-heading text-size-h3 bold mb-0">Melanie DeFilippis</p>
            <p class="text-dark-blue mb-0">Tech Startup Founder</p>
            <p class="text-dark-blue mb-0">CEO, Leto Maternal Health</p>
          </div>

        </div>

        <!-- END: Guest 3 -->

        <div class="col-12 col-lg-10 mt-4 text-center mx-auto">

          <p class="text-size-body pb-0 ps-2 ps-md-0 text-dark-blue text-start">
            Camp ended with a giant pizza party and Camp Showcase where campers showed off their new and and expanded websites to Savannah community leaders, the local media, family, and friends.
          </p>

        </div>

      </div>

    <!-- *************** END: Invited Guest Instructors & Speakers ************ -->

        
    <!-- ************* BEGIN: Winter Camp Button *********** -->

    <div class="button text-center mt-3">
      <a href="winter-camp-2023.html">
        <button type="button" class="hero-button-1 btn bg-hot-pink text-white">CHECK OUT WINTER CAMP 2023</button>
      </a>
    </div>

    <!-- ************* END: Winter Camp Button *********** -->


    <!-- ************* BEGIN: Winter Camp 2023 Sponsors *********** -->

      <div class="col-12 text-center mx-auto">     

      <div class="container">

        <div class="d-flex justify-content-center mt-4">
          <p class="serif-heading bold mx-auto text-center m-2 text-size-secondary-heading text-hot-pink">
            &nbsp;Thank You to Our Winter Camp 2023 Sponsors &#38; Supporters!&nbsp;
          </p>
        </div>

       <!-- BEGIN: Sponsor Logo Section -->

        <div class="row col-12 justify-content-center py-3">

          <!-- BEGIN: BIG & City of SAV -->

          <div class="row col-12 col-md-10 col-lg-4">

            <div class="col-7 pt-5">
              <a href="https://www.savannahga.gov" target="_blank">
                <img class="sponsor-logo-img" src="city-of-savannah.png" width="200" alt="city of savannah logo">
              </a>
            </div>

            <div class="col-5 pt-2">
              <a href="https://research.georgiasouthern.edu/big" target="_blank">
                <img class="sponsor-logo-img" src="business-innovation-group.jpg" height="90" alt="georgia southern business innovation group logo">
              </a>
            </div>

          </div>

          <!-- END: BIG & City of SAV -->

          <!-- BEGIN: Howmet & Howmet Women's -->

          <div class="row col-12 col-lg-4">

            <div class="col-6 mt-3 pt-3">
              <a href="https://www.howmet.com/foundation" target="_blank">
                <img class="sponsor-logo-img" src="howmet-aerospace.png" width="200" alt="howmet aerospace logo">
              </a>
            </div>
              
            <div class="col-6 mt-0 pt-2">
              <a href="https://www.howmet.com/diversity-equity-inclusion" target="_blank">
                <img class="sponsor-logo-img" src="howmet-womens-network.png" height="100" alt="howmet women's network logo">
              </a>
            </div>

          </div>

          <!-- END: Howmet & Howmet Women's -->

        </div>

      </div>

      <!-- END: Sponsor Logo Section -->

    <!-- *************** END: Winter Camp 2023 Sponsors ************ -->

</div> <!-- container -->

<!-- **************************************** END: WINTER CAMP 2023 *********************************************** -->
<!-- ************************************************************************************************************** -->

    `
  }
}


// *********************** Define 'extends HTMLElements' Above *********************** //

customElements.define('wc2023-feature', WC2023Feature);
