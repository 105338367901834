// *************** Custom Configuration Production *****************//

export const configProd = {
  Auth: {
    Cognito: {
      region: 'us-east-2',
      userPoolClientId: '2kvrjq3h0giq06f3m7tbp642to',
      userPoolId: 'us-east-2_Kes793HAb',
    }
  },
};