import Amplify from '@aws-amplify/api';

// ****************************** Import CSS ******************************** //

import "./index.css";

// ****************************** Import Images ******************************** //

// ** LOADING SPINNER - GCS Computer ** //
import "./assets/logos/gcs-logo-pink-comp-only.png";

// ** Sponsors ** //
import "./assets/sponsor-logos/business-innovation-group.jpg";
import "./assets/sponsor-logos/city-of-savannah.png";
import "./assets/sponsor-logos/city-of-savannah-white.png";
import "./assets/sponsor-logos/fly-corps.png";
import "./assets/sponsor-logos/howmet-aerospace.png";
import "./assets/sponsor-logos/howmet-womens-network.png";
import "./assets/sponsor-logos/sams-club-blue-bkgnd.png";
import "./assets/sponsor-logos/live-oak-public-libraries.png";
import "./assets/sponsor-logos/bud-johnson-photography.png";

// ** Banner Inages ** //
import "./assets/icons/snowflakes-white.png";

// ** Hero Inages ** //
import "./assets/hero-images/gcs-hero-image-wide.png";
import "./assets/hero-images/gcs-hero-image-long-1.png";
import "./assets/hero-images/gcs-hero-image-long-2.png";

// ** HTML Bracket Images ** //
import "./assets/icons/white-brackets.png";


// **************************** Add Top Nav & Footer ************************** //

import './nav-header-footer/nav-header-footer.js';
import './nav-header-footer/nav-header-footer.css';

// ************ Add Email Signup *************** //

import './nav-header-footer/email-contact-new-classes.js';

// **************************** Add SC2024 Feature ************************** //

import './homepage-features/5-sc2024-feature/sc2024-feature.js';

// **************************** Add SS2024 Feature ************************** //

import './homepage-features/4-ss2024-feature/ss2024-feature.js';

// **************************** Add WC2023 Feature ************************** //

import './homepage-features/3-wc2023-feature/wc2023-feature.js';



// **************************** Loading Spinner ****************************** //

$(window).on('load', function(){
  document.getElementById("loading-spinner").style.display = "block";
  setTimeout(removeSpinner, 1000);
});

function removeSpinner(){
  $( "#loading-spinner" ).fadeOut(100, function() {
    $( "#loading-spinner" ).remove();
  });
}

// **************** Function Spinner Show & Hide ******************** //

  function spinnerShow() {
      document.getElementById("function-spinner").style.display = "block";
  }

  function spinnerHide() {
      document.getElementById("function-spinner").style.display = "none";
  }



// **************************** Change InnerHTML of Hero Button for Particular Screen Width ****************************** //

const viewPortWidth = window.innerWidth;

const aboutUsButton = document.getElementById('about-us-button');

const campsButton = document.getElementById('camps-button');

if (viewPortWidth == '1280') {

  aboutUsButton.innerHTML = `ABOUT US`;

  campsButton.innerHTML = `SEE CAMPS & CLASSES`;
}
