import Amplify from '@aws-amplify/api';

// *************************** Import CSS *************************** //

import "./ss2024-feature.css";

// *************************** Import Images *************************** //

import "/src/assets/spring-sessions-2024/ss2024-pacman-student-photo.png";

// ** Sponsors ** //
import "/src/assets/sponsor-logos/city-of-savannah.png";
import "/src/assets/sponsor-logos/howmet-aerospace.png";
import "/src/assets/sponsor-logos/howmet-womens-network.png";
import "/src/assets/sponsor-logos/live-oak-public-libraries.png";

// *************************************************** Insert Spring Sessions 2024 Feature **************************************************** //

class SS2024Feature extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

<!-- *********************************** BEGIN: SPRING 2024 SATURDAY SESSIONS *************************************** -->

   <!-- ************** BEGIN: Title of Section ************** -->

    <div class="container">
  
      <div class="col-lg-12 text-center mb-4">

        <span class="col-3 col-md-2 col-lg-1 badge badge-dark-purple text-white mt-4">
          <img src="white-brackets.png" height="30px" alt="html brackets">
        </span>

        <div class="row col-12 d-flex justify-content-center text-center mx-auto mt-2">

          <p class="col-12 col-md-6 section-title-size serif-heading bold text-center text-md-end p-0 m-0 text-white">
              Spring 2024
          </p>

          <p class="col-12 col-md-6 section-title-size serif-heading bold text-center text-md-start p-0 ps-md-2 m-0 text-white">
              Saturday Sessions
          </p>

        </div>

        <p class="section-title-size serif-heading bold mx-auto text-center mt-0 pt-0 pb-0 mb-2 text-white">
            A Banner Moment for Website Animation
        </p>

      </div>

  </div>

  <!-- ************** END: Title of Section ************** -->


  <!-- ************** BEGIN: Animated PacMan Banner ************** -->

  <div class="container-fluid">

    <div class="row col-12 col-lg-12 d-flex justify-content-center text-center mx-auto">

      <div class="banner col-lg-12">

        <div class="banner-background">                   
      
        <!----------------- Chaser ----------------->
      
        <div class="chaser"></div>              
        
        <!--------------- Begin: Runner ------------->
        
        <div class="runner">                
          
          <div class="eyes"></div>            
        
          <div class="clothes"></div>           
        
        </div>                        
        
        <!---------------- End: Runner --------------->
        
        </div>                          

      </div>

    </div>

  </div>

  <!-- ********** END: Animated PacMan Banner ********** -->


  <!-- ************* BEGIN: Spring 2024 Saturday Sessions Description *********** -->

  <div class="container">

    <div class="col-12 col-lg-9 mt-2 pt-3 mt-xl-0 text-center mx-auto">

      <p class="text-size-body pb-2 ps-2 ps-md-0 text-off-white text-start bold">
        Earlier this spring Girls Code Savannah once again partnered with 
        <a class="text-bright-blue serif-heading text-size-h3 bold" href="https://liveoakpl.org/home" target="_blank">Live Oak Public Libraries</a>
        to hold our FREE "Saturday Sessions" at different library locations across Savannah. 
        The sessions took place from 11:00 AM &#8208; 1:30 PM on four Saturdays throughout March &#38; April.
      </p>

      <p class="text-size-body pb-2 ps-2 ps-md-0 text-off-white text-start bold">
        20 to 30 students attended each of the four Saturday Sessions. Our beginner students used HTML & CSS coding languages to 
        <a class="text-bright-blue serif-heading text-size-h3 bold" href="student-websites.html">build their own websites</a>
        from a blank screen. Our returning students continued evolving their 
        <a class="text-bright-blue serif-heading text-size-h3 bold" href="student-websites.html">already-created websites</a> by learning how to code 
        animated banners like the PacMan banner seen above.
      </p>

      <div class="row col-12 col-md-10 d-flex justify-content-center text-center mx-auto">
        <div class="col-12 pt-1">
            <img class="pacman-photo" src="ss2024-pacman-student-photo.png" alt="photo of students coding">
        </div>
      </div>

      <p class="text-size-body pt-4 pb-2 ps-2 ps-md-0 text-off-white text-start bold">
        As always, we are incredibly thankful to our generous sponsors and partners who support our efforts to get students animated about 
        building their own technology. Please 
        <a class="text-bright-blue serif-heading text-size-h3 bold" href="contact.html">contact us</a>
        or 
        <a class="text-bright-blue serif-heading text-size-h3 bold" href="donate.html">donate</a> if you would also like to provide support.
      </p>

    </div>

    <!-- *************** END: Spring 2024 Saturday Sessions Description ************ -->


    <!-- *************** BEGIN: Spring 2024 Sponsors ************ -->

      <div class="col-12 text-center mx-auto">     

      <div class="container">

        <div class="d-flex justify-content-center mt-3">
          <p class="serif-heading bold mx-auto text-center m-2 text-size-secondary-heading text-white">
            &nbsp;Thank You to Our Spring 2024 Sponsors &#38; Partners!&nbsp;
          </p>
        </div>

      <!-- BEGIN: Sponsor Logo Section -->

        <div class="row col-12 justify-content-center py-3">

      <!-- BEGIN: BIG & BJ Photography -->

          <div class="row col-12 col-md-10 col-lg-4">

          <div class="col-7 mt-2 pt-4">
            <a href="https://www.savannahga.gov" target="_blank">
              <img class="sponsor-logo-img" src="city-of-savannah.png" width="200" alt="city of savannah logo">
            </a>
          </div>

          <div class="col-5 live-oak-img bg-white">
              <a href="https://liveoakpl.org/home" target="_blank">
                <img class="sponsor-logo-img" src="live-oak-public-libraries.png" height="70" alt="live oak libraries logo">
              </a>
          </div>

          </div>

    <!-- END: BIG & BJ Photography -->

    <!-- BEGIN: Howmet & Howmet Women's -->

          <div class="row col-12 col-lg-4">

            <div class="col-6 mt-3 pt-3">
              <a href="https://www.howmet.com/foundation" target="_blank">
                <img class="sponsor-logo-img" src="howmet-aerospace.png" width="200" alt="howmet aerospace logo">
              </a>
            </div>
              
            <div class="col-6 mt-0 pt-2">
              <a href="https://www.howmet.com/diversity-equity-inclusion" target="_blank">
                <img class="sponsor-logo-img" src="howmet-womens-network.png" height="100" alt="howmet women's network logo">
              </a>
            </div>

          </div>

    <!-- END: Howmet & Howmet Women's -->

        </div>

    <!-- END: Sponsor Logo Section -->

      </div>

  <!-- *************** END: Spring 2024 Sponsors ************ -->

     </div>

<!-- *********************************** END: SPRING 2024 SATURDAY SESSIONS ***************************************** -->

    `
  }
}


// *********************** Define 'extends HTMLElements' Above *********************** //

customElements.define('ss2024-feature', SS2024Feature);