
// **************************** New Classes Email Contact Form ****************************** //

  const scriptURL = 'https://script.google.com/macros/s/AKfycbwWmxNvqWz3plC9ZWuZcEPVUhlY3_O5rVA2pVtBNJbpXYaWc6u6QDOMU-aHYDG-q-n_mw/exec'

  const form = document.forms['submit-to-google-sheet'];

  const firstName = document.getElementById('firstName');

  const lastName = document.getElementById('lastName');

  const email = document.getElementById('email');

  // Get the modal
  var modal = document.getElementById("emailModal");

  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("close")[0];

  const modalHeading = document.getElementById("modal-heading");

  const modalText = document.getElementById("modal-text");



  form.addEventListener('submit', e => {
    
    e.preventDefault()

    showSpinner()

    // console.log('First Name:', firstName.value);

    // console.log('Last Name:', lastName.value);

    // console.log('Email:', email.value);


    if (firstName.value.trim() == null || firstName.value.trim() == '') {

        hideSpinner()

        modal.style.display = "block";

        modalHeading.innerHTML = `Oops!`;

        modalText.innerHTML = `Please enter your first name.`;

        span.onclick = function() {
          modal.style.display = "none";
          modalHeading.innerHTML = ``;
          modalText.innerHTML = ``;
        }

    }


    else if (lastName.value.trim() == null || lastName.value.trim() == '') {

        hideSpinner()

        modal.style.display = "block";

        modalHeading.innerHTML = `Oops!`;

        modalText.innerHTML = `Please enter your last name.`;

        span.onclick = function() {
          modal.style.display = "none";
          modalHeading.innerHTML = ``;
          modalText.innerHTML = ``;
        }
    }


    else if (email.value.trim() == null || email.value.trim() == '') {

        hideSpinner()

        modal.style.display = "block";

        modalHeading.innerHTML = `Oops!`;

        modalText.innerHTML = `Please enter your email.`;

        span.onclick = function() {
          modal.style.display = "none";
          modalHeading.innerHTML = ``;
          modalText.innerHTML = ``;
        }
    }

  
  else if (email.value.trim() != null) {

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value.trim())) {

      fetch(scriptURL, { method: 'POST', body: new FormData(form)})
      
      .then(response => {

        hideSpinner()

        // Open the modal 
        modal.style.display = "block";

        modalHeading.innerHTML = `Thank you!`;

        modalText.innerHTML = `We will email you when we announce new camps and classes.`;

        // When the user clicks on <span> (x), close the modal
        span.onclick = function() {
          modal.style.display = "none";
          form.reset()
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
          if (event.target == modal) {
            modal.style.display = "none";
            form.reset()
          }
        }
        
      })

      .catch(error => console.error('Error!', error.message))
    }

    else { 

      hideSpinner()

      modal.style.display = "block";

      modalHeading.innerHTML = `Oops!`;

      modalText.innerHTML = `This is not a valid email address.`;
    
      span.onclick = function() {
        modalHeading.innerHTML = `""`;
        modalText.innerHTML = `""`;
        modal.style.display = "none";
      }
    }
  }

})

  function showSpinner() {
    document.getElementById('spinner')
            .style.display = 'block';

    document.getElementById('submit-text')
            .style.display = 'none';
} 

function hideSpinner() {
    document.getElementById('spinner')
            .style.display = 'none';

    document.getElementById('submit-text')
            .style.display = 'block';
} 