import Amplify from '@aws-amplify/api';

// *************************** Import CSS *************************** //

import "./sc2024-feature.css";

// *************************** Import Images *************************** //

// ** SUMMER CAMP 2024 - Feature ** //

// ** GCS Logos ** //
import "/src/assets/logos/gcs-logo-pink-background.png";
import "/src/assets/logos/gcs-blue-logo.png";

// ** Polaroids Group 1 ** //
import "/src/assets/summer-camp-2024/polaroids/sc2024-returning-class-b.jpg";
import "/src/assets/summer-camp-2024/polaroids/sc2024-beginner-class-b.jpg";
import "/src/assets/summer-camp-2024/polaroids/sc2024-beginner-class-a.jpg";
import "/src/assets/summer-camp-2024/polaroids/sc2024-binary-bracelets.jpg";

// ** Polaroids Group 2 ** //
import "/src/assets/summer-camp-2024/polaroids/sc2024-reilly-students.jpg";
import "/src/assets/summer-camp-2024/polaroids/zoe-teaching.jpg";
import "/src/assets/summer-camp-2024/polaroids/sc2024-payton-students.jpg";

// ** Polaroids Group 3 ** //
import "/src/assets/summer-camp-2024/polaroids/kristie-calise-headshot-square.jpg";
import "/src/assets/summer-camp-2024/polaroids/sc2024-girls-coding.jpg";
import "/src/assets/summer-camp-2024/polaroids/sc2024-students-at-computers-1.jpg";

// ** Polaroids Group 4 ** //
import "/src/assets/summer-camp-2024/polaroids/sc2024-girls-smiling.jpg";
import "/src/assets/summer-camp-2024/polaroids/sc2024-student-website-1.jpg";
import "/src/assets/summer-camp-2024/polaroids/sc2024-lesli-daniette-hug-half.jpg";

// ** SUMMER CAMP 2024 - Camp Sponsors ** //
import "/src/assets/sponsor-logos/gsu-logo-with-border.jpg";
import "/src/assets/sponsor-logos/business-innovation-group.jpg";
import "/src/assets/sponsor-logos/bud-johnson-photography.png";
import "/src/assets/sponsor-logos/howmet-aerospace.png";
import "/src/assets/sponsor-logos/howmet-womens-network.png";


// *************************************************** Insert Summer Camp 2024 Feature **************************************************** //

class SC2024Feature extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

<!-- *********************************** BEGIN: SUMMER CAMP 2024 *************************************** -->

  <div class="container">

  <!-- ************** BEGIN: Title & Pink + Blue Logos ************** -->

    <div class="row">

      <div class="col-lg-12 text-center mb-4">

      <span class="col-3 col-md-2 col-lg-1 badge badge-dark-purple text-white mt-4">
        <img src="white-brackets.png" height="30px" alt="html brackets">
      </span>

        <p class="section-title-size serif-heading bold mx-auto text-center mt-2 pb-0 mb-0 text-white">
          Summer Camp 2024
        </p>

        <p class="section-title-size serif-heading bold mx-auto text-center mt-0 pt-0 pb-0 mb-2 text-white">
          More Than We Imagined!
        </p>

      </div>

      <div class="pink-logo col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

        <a href="" class="polaroid-logo img8"><img src="gcs-logo-pink-background.png" alt=""></a>

      </div>

      <div class="blue-logo col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

        <a href="" class="polaroid-logo img8"><img src="gcs-blue-logo.png" alt=""></a>

      </div>

    </div>

  <!-- ************** END: Title & Pink + Blue Logos ************** -->


  <!-- ***************** BEGIN: Polaroid Photos ****************** -->

  <div class="photo-album">

    <div class="row col-12 d-flex justify-content-center mx-auto">

      <!-- ************** BEGIN: Photos Group 1 ************** -->

      <div class="row col-12 col-md-6 col-lg-3">

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="medium-wide medium-wide-1 polaroid drop-shadow img1"><img src="sc2024-returning-class-b.jpg" alt=""></a> 

        </div>

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="medium-wide medium-wide-2 polaroid drop-shadow img2"><img src="sc2024-beginner-class-b.jpg" alt="">MORE GIRLS</br>(AND MORE BOYS)!</a>

          <a href="summer-camp-2024.html" class="polaroid drop-shadow img3"><img src="sc2024-beginner-class-a.jpg" alt=""></a>

        </div>

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

           <a href="summer-camp-2024.html" class="medium polaroid drop-shadow img4"><img src="sc2024-binary-bracelets.jpg" alt="">MORE CLASSES!</a>

        </div>

      </div> <!-- Photos Group 1 -->

      <!-- ************** END: Photos Group 1 ************** -->

      <!-- ************** BEGIN: Photos Group 2 ************** -->

      <div class="row col-12 col-md-6 col-lg-3">

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="large polaroid drop-shadow img5"><img src="sc2024-reilly-students.jpg" alt="">MORE INSTRUCTORS!</a>

        </div>

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="medium polaroid drop-shadow img6"><img src="zoe-teaching.jpg" alt=""></a>

        </div>

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="polaroid drop-shadow img7"><img src="sc2024-payton-students.jpg" alt=""></a>
       
        </div> 

      </div> <!-- Photos Group 2 -->

      <!-- ************** END: Photos Group 2 ************** -->

      <!-- ************** BEGIN: Photos Group 3 ************** -->

      <div class="row col-12 col-md-6 col-lg-3">

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="small polaroid drop-shadow img8"><img src="kristie-calise-headshot-square.jpg" alt="">MORE SPEAKERS!</a>

        </div>

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="large large-2 polaroid drop-shadow img9"><img src="sc2024-girls-coding.jpg" alt="">MORE CODING!</a>

        </div>

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="medium polaroid drop-shadow img10"><img src="sc2024-students-at-computers-1.jpg" alt="">MORE COMPUTERS!</a>
        
        </div>

      </div> <!-- Photos Group 3 -->

      <!-- ************** END: Photos Group 3 ************** -->

      <!-- ************** BEGIN: Photos Group 4 ************** -->

       <div class="row col-12 col-md-6 col-lg-3">

         <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="polaroid drop-shadow img11"><img src="sc2024-girls-smiling.jpg" alt=""></a>

        </div>
        
        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="large large-2 polaroid drop-shadow img12"><img src="sc2024-student-website-1.jpg" alt="">MORE WEBSITES!</a>

        </div>

        <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">

          <a href="summer-camp-2024.html" class="medium polaroid drop-shadow img13"><img src="sc2024-lesli-daniette-hug-half.jpg" alt="">MORE JOY!</a>

        </div>

      </div>  <!-- Photos Group 4 -->

      <!-- ************** END: Photos Group 4 ************** -->

    </div>

  </div> <!-- photo album -->

<!-- ************** END: Polaroid Photos ************** -->


<!-- ************** BEGIN: Summer Camp 2024 - Button & Sponsors ************** -->

    <div class="row col-12 col-lg-10 d-flex justify-content-center mt-1 mb-3 text-center mx-auto">

    <!-- ************* BEGIN: Summer Camp 2024 Button *********** -->

    <div class="button text-center mt-4">
      <a href="summer-camp-2024.html">
        <button type="button" class="hero-button-3 btn bg-hot-pink text-white">CHECK OUT SUMMER CAMP 2024</button>
      </a>
    </div>

    <!-- ************* END: Summer Camp 2024 Button *********** -->






    <!-- *************** BEGIN: Summer Camp 2024 Sponsors ************ -->

    <div class="col-12">     


      <div class="container m-0 p-0">

        <div class="d-flex justify-content-center mt-3">
          <p class="thank-you-text serif-heading bold mx-auto text-center m-2 text-size-secondary-heading text-white">
            &nbsp;Thank You to Our Summer Camp 2024 Sponsors &#38; Supporters!&nbsp;
          </p>
        </div>


        <!-- BEGIN: Sponsor Logo Section -->
        
        <div class="row justify-content-center mx-auto">




          
          <!-- BEGIN: Sponsor Group 1 -->

          <div class="row col-12 col-lg-4 py-3">

          <!-- BEGIN: GA Southern -->

          <div class="col-7 col-md-6 col-lg-7 pt-3">
            <a href="https://www.georgiasouthern.edu/campuses/armstrong-campus-savannah/" target="_blank">
              <img class="sponsor-logo-img" src="gsu-logo-with-border.jpg" height="70" alt="georgia southern university logo">
            </a>
          </div>

          <!-- END: GA Southern -->

          
          <!-- BEGIN: BIG -->

          <div class="col-5 col-md-6 col-lg-5 pt-2">
            <a href="https://research.georgiasouthern.edu/big" target="_blank">
              <img class="sponsor-logo-img" src="business-innovation-group.jpg" height="80" alt="georgia southern business innovation group logo">
            </a>
          </div>

          <!-- END: BIG -->

          </div>





          <!-- BEGIN: Sponsor Group 2 -->

          <div class="row col-12 col-lg-3 py-lg-3">

          <!-- BEGIN: BJ Photography -->

          <div class="col-12 p-0 pt-md-3">
            <a href="https://www.budjohnsonphotography.com/" target="_blank">
              <img class="sponsor-logo-img" src="bud-johnson-photography.png" height="70" alt="bud johnson photography logo">
            </a>
          </div>

          <!-- END: BJ Photography -->

          </div>




          <!-- BEGIN: Sponsor Group 3 -->

          <div class="row col-12 col-lg-4 py-lg-3">

          <!-- BEGIN: Howmet -->

            <div class="col-7 col-md-6 col-lg-7 pt-4">
              <a href="https://www.howmet.com/foundation" target="_blank">
                <img class="sponsor-logo-img" src="howmet-aerospace.png" width="200" alt="howmet aerospace logo">
              </a>
            </div>
              
          <!-- END: Howmet -->

          <!-- BEGIN: Howmet Women's -->

          <div class="col-5 col-md-6 col-lg-5 p-0">
            <a href="https://www.howmet.com/diversity-equity-inclusion" target="_blank">
              <img class="sponsor-logo-img" src="howmet-womens-network.png" height="100" alt="howmet women's network logo">
            </a>
          </div>
              
          <!-- END: Howmet Women's -->

          </div>



          



        </div>

      <!-- END: Sponsor Logo Section -->

      </div> <!--container-->

    </div>

    <!-- *************** END: Summer Camp 2024 Sponsors ************ -->

  </div>

  <!-- ************** END: Summer Camp 2024 - Button & Sponsors ************** -->

  </div>

<!-- ****************************************** END: SUMMER CAMP 2024 *********************************************** -->

    `
  }
}


// *********************** Define 'extends HTMLElements' Above *********************** //

customElements.define('sc2024-feature', SC2024Feature);